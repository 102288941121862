
import { useMemo } from 'react'
import { PopupsInfo } from '../../data/preheaders-popup/popup'
import useConfig from '../../hooks/config/useConfig'
import { ImageModal } from './ImageModal'
import { RandomImagePopup } from './RandomImagePopup'

export const PopupMain = () => {
    
    const { country } = useConfig()
    const popupsInfoCountry = PopupsInfo.find( p => p.country == country)
    const popups = popupsInfoCountry?.popups.filter( r => !!r.active) || []
    
    const isRandom = popups.length > 1 ? true : false

    if(!popupsInfoCountry || popups.length <= 0) return null;
    const firstPopup = popups[0]

    return (
        <>
        <div className="container">
            {
                isRandom ? 
                <RandomImagePopup popups={popups} /> : 
                <ImageModal 
                    cookieName={firstPopup.cookieName}  
                    desktopImg={firstPopup.desktopImg}
                    mobileImg={firstPopup.mobileImg}
                    link={firstPopup.link}
                    endDate={firstPopup.endDate}
                    name={firstPopup.name}

                />
            }
        </div>
        </>
    )

}