import React, { useState,useMemo, useEffect, useRef } from "react";
import {
  Button,
  Col,
  Container,
  Nav,
  Navbar,
  NavDropdown,
  Offcanvas,
  Accordion,
  OverlayTrigger,
  Tooltip
} from "react-bootstrap";
import { redirect, useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import useUser from "../../hooks/user/useUser";
import useBreakpoint from "use-breakpoint";
import { BREAKPOINTS } from "../../utils/Constants";
import { CustomImage } from "../../components/CustomImage/CustomImage";
import useCountry from "../../hooks/config/useConfig";
import { NotificationsDropdown } from "../../components/NotificationsDropdown/NotificationsDropdown";
import { FeedBenefitsModal } from "../../components/FeedBenefitsModal/FeedBenefitsModal";
import { FeedBenefitsForm } from "../../components/FeedBenefitsModal/FeedBenefitsForm";
import { useFetch } from "../../hooks/fetch/useFetch";
import { useCookies } from "../../hooks/cookies/useCookies";
import { WarrantyModal } from "../../components/FeedBenefitsModal/WarrantyModal";
import { useGoogleAnalytics } from "../../hooks/googleAnalytics/useGoogleAnalytics";
import { IconUser } from "../../components/CustomIcons/IconUser";
import { IconCoin } from "../../components/CustomIcons/IconCoin";
import { IconClockHistory } from "../../components/CustomIcons/IconClockHistory";
import { IconHeart } from "../../components/CustomIcons/IconHeart";
import { useDataKeys } from "../../hooks/dataKeys/useDataKeys";
import { HeaderTabs } from "../../interfaces/config";
import { usePromo } from "../../hooks/promo/usePromo";
import { NotarialModal } from "../../components/FeedBenefitsModal/NotarialModal";
import { useLinks } from "../../hooks/config/useLinks";

interface linkType  {
  title? : any,
  link? : string,
  show? : any,
  placement? : string,
  action? : Function,
  key? : HeaderTabs,
  variant?:any,
  dropdown? : any,
  mobileDropdown ? : any
}

export const Header = () => {
  const { userState, isLoggedIn , isAdmin } = useUser();
  const { breakpoint } = useBreakpoint(BREAKPOINTS, "xs");
  const { pathname , search} = useLocation();
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const { country, translation, countryFeatures , config } = useCountry();
  const [viewedExist, setViewedExist] = useState(false);
  const [showWarranty, setShowWarranty] = useState(false);
  const [showMortgage, setShowMortgage] = useState(false);
  const [showNotarial, setShowNotarial] = useState(false);
  const [openTooltip , setOpenTooltip] = useState(true)
  const {logout} = useFetch()
  const { getCookie, setCookie } = useCookies();
  const GA = useGoogleAnalytics()
  const refToggle = useRef<any>(null)

  const navigate = useNavigate()
  const fullLink = pathname+search

  const { countryPromo } = usePromo()
  const { headerLinks , activeProjectsTab } = useLinks()

  const logoImg = 'https://cdn1.infocasas.com.uy/web/61eee8ead10a6_infocdn__brand_logo_400x200.png'

  
  const userOptions = [
    {
      title: (
        <>
          <IconUser width={18} />
          <span className="mx-2">Perfil</span>
        </>
      ),
      action: () => {
        GA.Event({ category: `User access profile`, action: `user_access_profile`, label: `${userState.name}`, value: Number(userState.id) });
        navigate('/usuario/perfil')
        
      } ,
      visible : true
    },
    {
      title: (
        <>
          <IconCoin width={18} />
          <span className="mx-2">Mis Ventas</span>
        </>
      ),
      action: () => {
        GA.Event({ category: `User access sales`, action: `user_access_sales`, label: `${userState.name}`, value: Number(userState.id) });
        navigate('/usuario/ventas')
        
      } ,
      visible : true
    },
    {
      title: (
        <>
          <IconHeart width={18} />
          <span className="mx-2">Mis Favoritos</span>
        </>
      ),
      action: () => {
        GA.Event({ category: `User access favorites`, action: `user_access_favorites`, label: `${userState.name}`, value: Number(userState.id) });
        navigate('/usuario/favoritos')
        
      } ,
      visible : true
    },
    {
      title: (
        <>
          <IconClockHistory width={18} />
          <span className="mx-2">Mi actividad</span>
        </>
      ),
      action: () => {
        GA.Event({ category: `User access activity`, action: `user_access_activity`, label: `${userState.name}`, value: Number(userState.id) });
        navigate('/usuario/actividad')
        
      } ,
      visible : true
    },
    
    {
      title: (
        <>
          <i className="bi bi-building-lock fs-md me-2 "></i>
          Mis {translation.rent_warranties}
        </>
      ),
      action: () => {
        GA.Event({ category: `User access warranties`, action: `user_access_warranties`, label: `${userState.name}`, value: Number(userState.id) });
        navigate('/garantias-alquiler')
        
      } ,
      visible : !!countryFeatures.rentWarrantyPanel 
    },
    {
      title: (
        <div >
          <i className="bi bi-arrow-right fs-md me-2 "></i>
          Ir a Administrador
        </div>
      ),
      action: () => {
        navigate('/admin')
      } ,
      visible : isAdmin
    },
    {
      title: (
        <>
          <i className="bi bi-box-arrow-left fs-md me-2"></i>
          Salir
        </>
      ),
      action: () => logout(userState),
      visible : true
    },
    
  ];

  const nameHeader = userState.real_estate? userState.name + ' - ' + userState.real_estate : userState.name

  const titleSession = (
    <span className="user-name text-muted me-2 d-inline-flex align-items-center lh-sm">
      <>
        <img
          alt={userState?.name}
          src={(userState?.image != "" && userState?.image != null) ? userState?.image : "https://cdn1.infocasas.com.uy/web/62bdd922446a2_user-no-image.png"}
          width={35}
          height={35}
          className="rounded-circle me-2 mt-1"
          style={{height: "35px" ,objectFit : "contain"}}
        />
      </>

      <p className="text-wrap mb-0">{nameHeader}</p>
      <i className="bi bi-chevron-down ms-2"></i>
    </span>
  )
  
  const links = useMemo(() => {
    
    const _links : linkType[] = [
      {
        title: "Venta",
        link: headerLinks.all,
        show: isLoggedIn && !countryFeatures.hide_sales,
        placement: "left",
        action: () => {
          window.localStorage.setItem("OperationType", "sale");
        },
        key : 'sales'
      },
      {
        title: "Residencial",
        link: headerLinks.residency,
        show: isLoggedIn && !!countryFeatures.header_residency,
        placement: "left",
        action: () => {
          window.localStorage.setItem("OperationType", "sale");
        },
        key : 'residency'
      },
      {
        title: "Comercial",
        link: headerLinks.comercial,
        show: isLoggedIn && !!countryFeatures.header_comercial,
        placement: "left",
        action: () => {
          window.localStorage.setItem("OperationType", "sale");
        },
        key : 'comercial'
      },
      {
        title: translation.rent,
        link: headerLinks.all,
        show: isLoggedIn && countryFeatures.rent,
        placement: "left",
        action: () => {
          window.localStorage.setItem("OperationType", "rent");
        },
        key : 'rent'
      },
      {
        title: "Propiedades Usadas",
        link: '/propiedades-usadas',
        show: isLoggedIn && !!countryFeatures.used_properties,
        placement: "left",
        action: () => {
          window.localStorage.setItem("OperationType", "sale");
        },
        key : 'propiedades-usadas'
      },
      {
        title: translation.lands,
        link: headerLinks.lands,
        show: isLoggedIn && countryFeatures.land,
        placement: "left",
        action: () => {
          window.localStorage.setItem("OperationType", "sale");
        },
        key : 'lands'
      },
      {
        title: countryPromo?.title || '',
        link: headerLinks.promos,
        show: isLoggedIn && !!countryPromo,
        placement: "left",
        action: () => {
          window.localStorage.setItem("OperationType", "sale");
        },
        key : 'promo'
      },
      {
        show: isLoggedIn && (countryFeatures.group_services),
        placement: "left",
        dropdown: (
          <NavDropdown
            title={
              <span className="title-dropdown">
                Servicios
                <i className="bi bi-chevron-down ms-2"></i>
              </span>
            }
            className="header-dropdown"
            align="start"
          >
            {
              countryFeatures.mortgageLoan && 
              <NavDropdown.Item
                onClick={() => setShowMortgage(true)}
              >
                Préstamos hipotecarios
              </NavDropdown.Item>
            }
            {
              countryFeatures.rentWarranty && 
              <NavDropdown.Item
                onClick={() => countryFeatures.rentWarrantyPanel? navigate('/garantias-alquiler') : setShowWarranty(true)}
              >
                {translation.rent_warranties}
              </NavDropdown.Item>
            }
            {
              countryFeatures.notarial_service && 
              <NavDropdown.Item
                onClick={() => setShowNotarial(true)}
              >
                Servicios Notariales
              </NavDropdown.Item>
            }
            {
              countryFeatures.enable_appraisal && 
              <NavDropdown.Item
                onClick={() => navigate('/tasaciones')}
              >
                Tasaciones
              </NavDropdown.Item>
            }
            {
              !!countryFeatures.enable_iris_academy && !!countryFeatures.show_academy_in_services && 
              <NavDropdown.Item
                onClick={() => navigate('/cursos')}
              >
                Academy
              </NavDropdown.Item>
            }
            
          </NavDropdown>
        ),
        mobileDropdown: ( (countryFeatures.rentWarranty || countryFeatures.mortgageLoan) &&
          <Accordion defaultActiveKey="0" className="services-accordion">
            <Accordion.Item eventKey="0">
              <Accordion.Header>Servicios</Accordion.Header>
              <Accordion.Body>
                {countryFeatures.mortgageLoan && <Button className="service-button" onClick={() => {
                  setShowMortgage(true)
                }}>Préstamos hipotecarios</Button>} 
                
                {countryFeatures.rentWarranty && <Button className="service-button" onClick={() => {
                  setShowWarranty(true)
                  }}>{translation.rent_warranties}</Button>}

                { countryFeatures.notarial_service && <Button className="service-button" onClick={() => {
                  setShowNotarial(true)
                }}>Servicios Notariales</Button>}  
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        )
      },
      { title: translation.rent_warranties, 
        show: isLoggedIn && (countryFeatures.rentWarranty && !countryFeatures.group_services), 
        placement: "left" ,
        action : () => countryFeatures.rentWarrantyPanel? navigate('/garantias-alquiler') : setShowWarranty(true)
      },
      { title: 'Academy', link: "/cursos", show: isLoggedIn && !!countryFeatures.enable_iris_academy && !countryFeatures.show_academy_in_services, placement: "left" },
      { title: 'Notaría Virtual', link: "/notaria-virtual", show: isLoggedIn && !!countryFeatures.virtual_sign_service, placement: "left" , key : 'notaria-virtual'},
      { title: translation.news, link: "/feed", show: isLoggedIn, placement: "left" },
      {
        title: (
          <span>
            <i className="bi bi-person me-2 fs-5"></i>
            Acceder
          </span>
        ),
        link: "/iniciar-sesion",
        show: !isLoggedIn,
        placement: "right",
        variant: "primary",
      },
      {
        title: "Probar Ahora",
        link: "/registro",
        show: !isLoggedIn,
        placement: "right",
        variant: "outline-secondary",
      },
      {
        link: "/notifications",
        show: isLoggedIn,
        placement: "right",
        dropdown: <NotificationsDropdown type="desktop" viewedExist={viewedExist} setViewedExist={setViewedExist} />,
        mobileDropdown: <NotificationsDropdown type="mobile" viewedExist={viewedExist} setViewedExist={setViewedExist} />,
      },
      {
        title: titleSession,
        link: "/iniciar-sesion",
        show: isLoggedIn,
        placement: "right",
        dropdown: (
          <OverlayTrigger key="bottom"
          placement="bottom"
          show={openTooltip && !!countryFeatures.rentWarrantyPanel}
          delay={{ show: 250, hide: 400 }}
          overlay={(props) => (
            <Tooltip className="alert-new" {...props}>
              <Button className="alert-new-close" onClick={closeTooltip}>
                <i className="bi bi-x-lg"></i>
              </Button>
              <div className="pt-2 pe-3 text-start">
                <span className="fw-bold">¡NUEVO!</span>
                <span> {translation.tooltip_profile}</span>
              </div>
            </Tooltip>
          )}>
            <NavDropdown
              title={titleSession}
              className="header-dropdown"
              align="end"
              onToggle={()=>setOpenTooltip(false)}
            >
              {userOptions.map((d, i) => (d.visible &&
                <NavDropdown.Item
                  onClick={d.action}
                  key={"drop_" + i}
                >
                  {d.title}
                </NavDropdown.Item>
              ))}
            </NavDropdown>
          </OverlayTrigger>
          
        ),
        mobileDropdown: (
          <>
            <Nav.Item className="mb-2 mt-3">{titleSession}</Nav.Item>
            {userOptions.map((d, i) => ( d.visible &&
              <Nav.Item
                className="ms-4 text-secondary p-2"
                onClick={() => {
                  d.action()
                  refToggle.current?.click()
                }}
                key={"drop_" + i + "_" + i}
              >
                {d.title}
              </Nav.Item>
            ))}
          </>
        )
      },
    ];

    return _links
  },[countryFeatures,openTooltip,translation,userState,config, headerLinks,countryPromo])

  function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

  const closeTooltip = () => {
    setCookie("showProfileNew", "=1;expires=-1;path=/;SameSite=none;Secure");
    setOpenTooltip(false);
  }


  useEffect(() => {
    if (!getCookie("showProfileNew")) {
      setOpenTooltip(true);
    } else {
      setOpenTooltip(false);
    }


    
  }, []);

  return (
    <>
      <Navbar bg="white" fixed="top" className="header border-bottom">
        <Container>
          <Navbar.Brand
            href="/feed"
            className={
              "py-0 " +
              (breakpoint === "xs" || breakpoint === "sm" || breakpoint === "md"
                ? "h-100 d-flex align-items-center gx-3"
                : "")
            }
          >
            {(breakpoint === "xs" ||
              breakpoint === "sm" ||
              breakpoint === "md") &&
              pathname === `/proyecto/${id}` ? (
              <Col className="back-arrow me-3" xs="auto">
                <div
                  onClick={() => window.history.back()}
                  className="fs-2 back"
                  role="button"
                >
                  <i className="text-light bi bi-arrow-left"></i>
                </div>
              </Col>
            ) : (
              ""
            )}
            <CustomImage
              classes="header-logo"
              src={logoImg}
              alt="Iris"
              width={50}
              height={50}
            />
          </Navbar.Brand>

          <Navbar.Collapse>
            {links
              .filter((l) => l.placement === "left" && l.show)
              .map(({ link, title, dropdown, action, key }, index) => {
                let isActive = false

                switch (title) {
                  case 'Venta':
                    if(key == activeProjectsTab) isActive = true
                    break;
                  case 'Residencial':
                      if(key == activeProjectsTab) isActive = true 
                      break;
                  case 'Comercial':
                        if(key == activeProjectsTab) isActive = true 
                        break;
                  case translation.rent:
                    if(key == activeProjectsTab) isActive = true 
                    break;
                  case 'Terrenos':
                    if(key == activeProjectsTab) isActive = true 
                    break;
                  case translation.news:
                    if (link === pathname) isActive = true;
                    break;
                  case 'Iris Academy':
                      if (link?.includes('cursos')) isActive = true;
                      break;
                  default:
                    if (link === pathname) isActive = true;
                    break;
                }

                if(key == 'promo' && fullLink?.includes('is_promo')) isActive = true

                if (typeof dropdown !== "undefined") {
                  return (
                    <div
                      key={"link_desktop_" + index}
                      className="d-none d-lg-block"
                    >
                      {dropdown}
                    </div>
                  )
                } else {
                  
                  return (
                      <Nav.Link
                          key={"link_desktop_" + index}
                          href={link}
                          active={isActive}
                          className={`${isActive ? "text-primary" : "text-secondary"
                            } d-none d-lg-block links-hoverable 
                            ${country == 'CL' ? 'chile' : ''}`}
                          onClick={(ev) => {
                            if (action) {
                              action();
                            }
                            
                          }}
                        >
                          {title}
                      </Nav.Link>
                    
                  )
                }
              })}
            <Nav className="ms-auto d-none d-lg-flex align-items-stretch">
              {links
                .filter((l) => l.placement === "right" && l.show)
                .map((l, index) =>
                  typeof l.dropdown !== "undefined" ? (
                    <div key={index}>
                      {l.dropdown}
                    </div>
                  ) : (
                    <Nav.Item
                      className="me-2"
                      key={"link_desktop_right_" + index}
                    >
                      <Button
                        variant={l.variant || "text"}
                        href={l.link}
                        className="h-100 d-flex align-items-center"
                      >
                        {l.title}
                      </Button>
                    </Nav.Item>
                  )
                )}
            </Nav>
          </Navbar.Collapse>

          <Navbar.Toggle
            aria-controls="offcanvasNavbar"
            className="d-lg-none d-block border-0 ms-auto toggle-button"
            ref={refToggle}
          >
            <i className="bi bi-list"></i>
            {viewedExist && <i className="bi bi-circle-fill text-primary notification-dot"></i>}
          </Navbar.Toggle>

          <Navbar.Offcanvas
            id="offcanvasNavbar"
            className="border-right"
            placement="start"
            
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title>
                <CustomImage
                  classes="offcanvas-logo logo-mobile"
                  src="https://cdn1.infocasas.com.uy/web/61eee8ead10a6_infocdn__brand_logo_400x200.png"
                  alt="Iris"
                  width={20}
                  height={20}
                />
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <Nav className="justify-content-end flex-grow-1 pe-3">
                {links
                  .filter((l) => l.placement === "left" && l.show)
                  .map((l, index) => {
                    if (typeof l.mobileDropdown !== "undefined") {
                      return (
                        <React.Fragment key={"link_right_" + index}  >
                          {l.mobileDropdown}
                        </React.Fragment>
                      )
                    } else {
                      return (
                        <Nav.Link
                          key={"link_" + index}
                          href={l.link}
                          className="text-secondary"
                          onClick={() => {
                            if (l.action) l.action();
                          }}
                        >
                          {l.title}
                        </Nav.Link>
                      )
                    }
                  })}
                {links
                  .filter((l) => l.placement === "right" && l.show)
                  .map((l, index) =>
                    typeof l.mobileDropdown !== "undefined" ? (
                      <React.Fragment key={"link_right_" + index}>
                        {l.mobileDropdown}
                      </React.Fragment>
                    ) : (
                      <Nav.Item key={"link_right_" + index}>
                        <Button
                          variant={l.variant || "text"}
                          href={l.link}
                          className="w-100 mt-2 text-secondary"
                        >
                          {l.title}
                        </Button>
                      </Nav.Item>
                    )
                  )}
              </Nav>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </Container>
      </Navbar>

      <div className="header-ghost"></div>
      <WarrantyModal showWarranty={showWarranty} setShowWarranty={setShowWarranty} />

      <FeedBenefitsModal show={showMortgage} setShow={setShowMortgage} type={"mortgage"}>
        <div className="row gx-5">
          <div className="col px-5">
            <p className="mb-3">Si tenés un cliente potencial, derivá sus datos al sistema de referidos de BBVA y recibí <strong>una bonificación al cierre del negocio.</strong></p>

            <ul className="ps-3 d-grid gap-3">
              <li>Completá los <strong>datos del cliente</strong></li>
              <li>BBVA se encargará de gestionar el análisis del crédito, aprobarlo y contactarse con el cliente</li>
              <li><strong>Una vez cerrado el proceso, se acreditará la bonificación al profesional inmobiliario que refirió el cliente</strong> (El cierre del crédito tiene un plazo de gestión variable, las bonificaciones se pagan a mes cerrado).</li>
            </ul>
          </div>
          <div className="col px-5">
            <FeedBenefitsForm show={showMortgage} type={"mortgage"} onSend={() => setShowMortgage(false)} />
          </div>
        </div>
      </FeedBenefitsModal>

      <NotarialModal 
        show={showNotarial} 
        setShow={setShowNotarial}
      />
    </>
  );
};
