import { PopupInfo } from "../../interfaces/ui";

export const PopupsInfo: PopupInfo[] = [
    {
        country: 'UY',
        popups: [
            {
                name : 'Talks',
                mobileImg: '/images/preheaders-popups/talks-Popup-Iris-Mobile.png',
                desktopImg: '/images/preheaders-popups/talks-Popup-Iris-Desk.png',
                link: 'https://www.infocasas.com.uy/proyectos/infocasas-talks/amp?utm_source=web&utm_medium=iris_popup',
                cookieName: 'talks2UY',
                endDate: '2024-09-11',
                active : true
            }
        ]
    },
    {
        country: 'CO',
        popups: [
            {
                name : 'Viaje Republica Dominicana',
                mobileImg: 'https://cdn1.infocasas.com.uy/web/66a7d2874a60e_viajemobile.png',
                desktopImg: 'https://cdn1.infocasas.com.uy/web/66a7d2877d404_viajedesktop.png',
                link: 'https://amp.fincaraiz.com.co/ViajeRepublicaDominicana-IRIS?utm_source=web&utm_medium=iris_popup',
                cookieName: 'openViajeRepDomCO',
                active : true
            },
            {
                name : 'Luxur Garden',
                mobileImg: 'https://cdn1.infocasas.com.uy/web/66e45c5ce0ec8_luxur_mobile.png',
                desktopImg: 'https://cdn1.infocasas.com.uy/web/66e45c5d1d605_luxur_desktop.png',
                link: 'https://amp.fincaraiz.com.co/iris-od-luxur-garden-18-09/amp?utm_source=web&utm_medium=iris_popup',
                cookieName: 'LuxurGardenCO',
                endDate: '2024-09-19',
                active : true
            },
            {
                name : 'Poblado Salamanca',
                mobileImg: 'https://cdn1.infocasas.com.uy/web/66e45d08b9fac_poblado_salamanca_mobile.png',
                desktopImg: 'https://cdn1.infocasas.com.uy/web/66e45d08bcab9_salamanca_popup_desktop.png',
                link: 'https://amp.fincaraiz.com.co/iris-od-poblado-salamanca-18-09/amp?utm_source=web&utm_medium=iris_popup',
                cookieName: 'SalamancaCO',
                endDate: '2024-09-19',
                active : true
            }
        ]
    },
    {
        country : 'PA',
        popups : [
            {
                name : 'Aptta',
                desktopImg: 'https://cdn1.infocasas.com.uy/web/66c64166f000c_aptta_desktop_popup.png',
                mobileImg: 'https://cdn1.infocasas.com.uy/web/66c641663039d_aptta_mobile_popup.png',
                link: "https://amp.encuentra24.com/openday_iris/pa?utm_source=web&utm_medium=iris_popup",
                cookieName: 'apptaPA',
                active: true,
                endDate : '2024-08-29'
            }
        ]
    }
]